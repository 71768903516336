import type { SearchResponse } from '@algolia/client-search';
import { Analytics } from '@gik/analytics';
import { AnalyticsEvents } from '@gik/analytics/utils/Events';
import { useBemCN } from '@gik/core/utils/bemBlock';
import getPreviewMode, { PreviewModeType } from '@gik/core/utils/PreviewMode';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { useSearchStore } from '@gik/search/components/SearchStore';
import type { AlgoliaObject, InkindSearchFilter, PostSearchFilter } from '@gik/search/components/useSearch';
import {
  NoResults,
  SearchResultsLoadingIndicator,
  SearchResultsPage,
} from '@gik/search/pages/SearchResultsPage/SearchResultsPage';
import { Button } from '@gik/ui/Button';
import { CenterFixed } from '@gik/ui/CenterFixed';
import { Container } from '@gik/ui/Container';
import { PageSection } from '@gik/ui/gik/PageSection';
import { LoadingSpinner } from '@gik/ui/LoadingSpinner';
import { NavBar } from '@gik/ui/NavBar';
import type { PopoverProps } from '@gik/ui/Popover';
import { SearchInput } from '@gik/ui/SearchInput';
import { Separator } from '@gik/ui/Separator';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import CloseIcon from '@heroicons/react/solid/XIcon';
import dynamic from 'next/dynamic';
import { Router } from 'next/router';
import React from 'react';
import useDebounce from 'react-use/lib/useDebounce';

const Popover = dynamic<PopoverProps>(() => import('@gik/ui/Popover/Popover').then(mod => mod.Popover), {
  ssr: false,
});

export interface ISearchDrawerProps {
  showRecent?: boolean;
  showMarketing?: boolean;
  showStartPageButton?: boolean;
}

function SearchDrawerComp({
  showRecent = false,
  showMarketing = false,
  showStartPageButton = false,
}: ISearchDrawerProps) {
  const bem = useBemCN('search-drawer');
  const searchIsOpen = useSearchStore(store => store.isOpen);

  // const carouselOptions = {
  //   slidesToShow: 3.5,
  //   adaptiveHeight: true,
  //   dots: false,
  //   infinite: false,
  // };

  function close() {
    useSearchStore.getState().close();
  }

  const { query, setQuery, navigationLoading, navigationStart } = useSearchStore(state => ({
    query: state.query,
    setQuery: state.setQuery,
    navigationLoading: state.navigationLoading,
    navigationStart: state.navigationStart,
  }));
  const [debouncedQuery, setDebouncedQuery] = React.useState<string>('');
  const blogPreviewMode = getPreviewMode(PreviewModeType.Blog);

  const inkindSearchFilters: InkindSearchFilter[] = React.useMemo(
    () => [{ key: 'hiddenFromSearch', value: false }],
    []
  );

  const postSearchFilter: PostSearchFilter[] = React.useMemo(
    () => (blogPreviewMode ? [] : [{ key: 'preview_mode', value: false }]),
    [blogPreviewMode]
  );

  useDebounce(() => setDebouncedQuery(query), 1000, [query]);
  const isPopperOpen = React.useMemo(() => query?.length >= 2 || false, [query]);

  const isPopoverOpen = React.useMemo(
    () => (showRecent || showMarketing || showStartPageButton) && isPopperOpen,
    [isPopperOpen, showMarketing, showRecent, showStartPageButton]
  );

  const [inkindSearchResults, setInkindSearchResults] = React.useState<SearchResponse<AlgoliaObject>>();
  const [productSearchResults, setProductSearchResults] = React.useState<SearchResponse<AlgoliaObject>>();
  const [postSearchResults, setPostSearchResults] = React.useState<SearchResponse<AlgoliaObject>>();

  const content = React.useMemo(() => {
    return (
      <>
        {showRecent && (
          <section {...bem('recent-pages')}>
            <h1 {...bem('title')}>Recent pages</h1>
            {/*<Carousel {...bem('recent-pages-section')} {...carouselOptions}>*/}
            {/*  {recent}*/}
            {/*</Carousel>*/}
          </section>
        )}

        {showMarketing && (
          <>
            <Separator />
            <section {...bem('marketing')}>
              {/*{marketingButtons.map((_button, key) => (*/}
              {/*  <div key={key} {...bem('chiclet-button-todo-ui-element')} />*/}
              {/*))}*/}
            </section>
          </>
        )}
      </>
    );
  }, [bem, showMarketing, showRecent]);

  function onInkindSearchChange(results: SearchResponse<AlgoliaObject>) {
    setInkindSearchResults(results);
  }
  function onProductSearchChange(results: SearchResponse<AlgoliaObject>) {
    setProductSearchResults(results);
  }
  function onPostSearchChange(results: SearchResponse<AlgoliaObject>) {
    setPostSearchResults(results);
  }

  function handleResultClicked() {
    navigationStart();
  }

  const routeChangeCompleteFn = () => {
    useSearchStore.getState().close();
  };

  React.useEffect(() => {
    Router.events.on('routeChangeComplete', routeChangeCompleteFn);

    return () => {
      Router.events.off('routeChangeComplete', routeChangeCompleteFn);
    };
  }, []);

  const [autofocusTrigger, setAutofocusTrigger] = React.useState<boolean>(false);
  useDebounce(
    () => {
      setAutofocusTrigger(searchIsOpen);
    },
    250,
    [searchIsOpen]
  );

  React.useEffect(() => {
    const hideBodyScrollClassName = 'backdrop-v2__body--visible';
    if (debouncedQuery.length > 0 && !document.body.classList.contains(hideBodyScrollClassName)) {
      document.body.classList.add(hideBodyScrollClassName);
    } else if (!(debouncedQuery.length > 0) && document.body.classList.contains(hideBodyScrollClassName)) {
      document.body.classList.remove(hideBodyScrollClassName);
    }
  }, [debouncedQuery]);

  React.useEffect(() => {
    if (debouncedQuery.length > 0) {
      Analytics.fireEvent(AnalyticsEvents.Search, {
        search_term: debouncedQuery,
      });
    }
  }, [debouncedQuery]);

  return (
    <>
      <div {...bem('drawer', [{ open: searchIsOpen }], ['no-print'])}>
        <NavBar contained {...bem('bar')}>
          <h1 {...bem('search-title')}>Search</h1>
          <Popover
            trigger={
              <div {...bem('drawer-content')}>
                <SearchInput
                  placeholder="Search Give InKind"
                  variant={'lightgrey'}
                  onKeyDown={({ key }) => key === 'Escape' && close()}
                  value={query}
                  onValueChange={setQuery}
                  autoFocus={autofocusTrigger}
                />
              </div>
            }
            hoverable
            isOpen={isPopoverOpen}
            hasArrowIndicator={false}
            placement={'bottom-start'}
            {...bem('popover')}
          >
            {content}
          </Popover>
          <div {...bem('close')}>
            <Button variant="default-extra-dark-plain" circle onClick={close}>
              <SvgIcon Icon={CloseIcon} />
            </Button>
          </div>
        </NavBar>
      </div>

      {searchIsOpen && (
        <div {...bem('results', [{ 'has-query': debouncedQuery.length > 0 || navigationLoading }])}>
          {debouncedQuery.length > 0 ? (
            <div {...bem('overflow-container')}>
              {(!inkindSearchResults || !productSearchResults || !postSearchResults) && (
                <SearchResultsLoadingIndicator />
              )}

              {navigationLoading ? (
                <CenterFixed {...bem('navigation-loading')}>
                  <LoadingSpinner />
                </CenterFixed>
              ) : (
                <>
                  {inkindSearchResults?.hits.length === 0 &&
                    productSearchResults?.hits.length === 0 &&
                    postSearchResults?.hits.length === 0 && (
                      <PageSection {...bem('no-results-section')}>
                        <NoResults query={debouncedQuery} />
                      </PageSection>
                    )}

                  <SearchResultsPage
                    query={debouncedQuery}
                    searchType={'inkinds'}
                    filters={inkindSearchFilters}
                    perPage={5}
                    onChange={onInkindSearchChange}
                    onClick={handleResultClicked}
                  />

                  {inkindSearchResults?.hits.length > 0 && productSearchResults?.hits.length > 0 && (
                    <Container>
                      <Separator />
                    </Container>
                  )}
                  <SearchResultsPage
                    query={debouncedQuery}
                    searchType={'products'}
                    perPage={5}
                    onChange={onProductSearchChange}
                    onClick={handleResultClicked}
                  />

                  {(inkindSearchResults?.hits.length > 0 || productSearchResults?.hits.length > 0) &&
                    postSearchResults?.hits.length > 0 && (
                      <Container>
                        <Separator />
                      </Container>
                    )}
                  <SearchResultsPage
                    query={debouncedQuery}
                    searchType={'posts'}
                    filters={postSearchFilter}
                    perPage={4}
                    onChange={onPostSearchChange}
                    onClick={handleResultClicked}
                  />
                </>
              )}
            </div>
          ) : (
            <div className="tw-flex-1" onClick={close} />
          )}
        </div>
      )}
    </>
  );
}

export const SearchDrawer = withComponentErrorBoundary(SearchDrawerComp);
