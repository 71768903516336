import Link from '@/components/Link';
import type { Hit, SearchResponse } from '@algolia/client-search';
import { useUserOrganizingPages, useUserOwnedPages } from '@gik/api/inkinds/userPages';
import { Breakpoint, useBreakpoint } from '@gik/core/hooks/hooks/BreakpointHooks';
import type { InkindPageAPIModel, Organizer } from '@gik/core/models/gik/InkindPage';
import { useUserStore } from '@gik/core/store/UserStore';
import { LayoutTypes } from '@gik/core/types/layouts';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { extractInkindPageRouteId } from '@gik/core/utils/LinkUtils';
import noop from '@gik/core/utils/noop';
import { Case, Switch } from '@gik/core/utils/SwitchCase';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { InkindPageCard } from '@gik/inkind-page/components/InkindPageCard/InkindPageCard';
import type {
  AlgoliaObject,
  InkindAlgoliaObject,
  InkindSearchFilter,
  IntercomArticleAlgoliaObject,
  PostAlgoliaObject,
  PostSearchFilter,
  ProductAlgoliaObject,
  ProductSearchFilter,
  SearchType,
} from '@gik/search/components';
import { useSearch } from '@gik/search/components';
import { Button } from '@gik/ui/Button';
import { ArticleTile } from '@gik/ui/gik/ArticleTile';
import { PageSection } from '@gik/ui/gik/PageSection';
import { ProductTile, ProductTileToolbar } from '@gik/ui/gik/ProductTile';
import { Grid } from '@gik/ui/Grid';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { DeterminationType, LoadingLinear } from '@gik/ui/LoadingLinear';
import { SvgIcon } from '@gik/ui/SvgIcon';
import SearchIcon from '@heroicons/react/solid/SearchIcon';
import he from 'he';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { translationKeys } from '../../i18n/en';

export type SearchResultsPageProps = {
  query: string;
  searchType: SearchType;
  perPage?: number;
  filters?: InkindSearchFilter[] | PostSearchFilter[] | ProductSearchFilter[];
  onChange?: (results: SearchResponse<AlgoliaObject>) => void;
  onClick?: (hit: Hit<AlgoliaObject>) => void;
};

function SearchResultsPageComp({
  query,
  searchType,
  filters,
  perPage = 5,
  onChange = noop,
  onClick = noop,
}: SearchResultsPageProps): React.ReactElement {
  const [_perPage, _setPerPage] = React.useState<number>(perPage);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const bem = useBemCN('search-results-page');
  const isMdDown = useBreakpoint(Breakpoint.MD_DOWN);
  const results = useSearch<AlgoliaObject>(query, searchType, filters, _perPage);

  React.useEffect(() => {
    _setPerPage(perPage);
  }, [perPage, query]);

  React.useEffect(() => {
    if (results) {
      setLoading(false);
      onChange(results);
    }
    // eslint-disable-next-line
  }, [results]);

  function showMore(total = results.nbHits) {
    if (!isLoading) {
      _setPerPage(total);
      setLoading(true);
    }
  }

  function resultClicked(hit: Hit<AlgoliaObject>) {
    onClick?.(hit);
  }

  if (!results || !results.hits || results?.hits?.length == 0) return null;

  return (
    <article {...bem()}>
      <PageSection noPadTop>
        <Switch value={searchType}>
          <Case value="inkinds">
            <h1 {...bem('title')}>Pages</h1>
          </Case>
          <Case value="products">
            <h1 {...bem('title')}>Products</h1>
          </Case>
          <Case value="posts">
            <h1 {...bem('title')}>Articles</h1>
          </Case>
          <Case value="helpArticles">
            <h1 {...bem('title')}>Knowledge Base</h1>
          </Case>
        </Switch>

        <Grid cols={isMdDown ? 1 : perPage} {...bem('results')}>
          {results.hits.map(hit => (
            <Switch key={hit.objectID} value={searchType}>
              <Case value="inkinds">
                <InkindSearchResult
                  hit={hit as Hit<InkindAlgoliaObject>}
                  onClick={() => resultClicked(hit)}
                  row={isMdDown}
                />
              </Case>
              <Case value="products">
                <ProductSearchResult
                  hit={hit as Hit<ProductAlgoliaObject>}
                  onClick={() => resultClicked(hit)}
                  row={isMdDown}
                />
              </Case>
              <Case value="posts">
                <PostSearchResult
                  hit={hit as Hit<PostAlgoliaObject>}
                  onClick={() => resultClicked(hit)}
                  row={isMdDown}
                />
              </Case>
              <Case value="helpArticles">
                <IntercomArticleSearchResult
                  hit={hit as Hit<IntercomArticleAlgoliaObject>}
                  onClick={() => resultClicked(hit)}
                  row={isMdDown}
                />
              </Case>
            </Switch>
          ))}
        </Grid>
        {!isLoading && results.hits.length !== results.nbHits && (
          <div {...bem('show-more')}>
            <Button
              onClick={() => showMore(_perPage + perPage * 2)}
              variant={'default-light'}
              fullWidth={isMdDown}
              pill
            >
              See more
            </Button>
          </div>
        )}
        {isLoading && <SearchResultsLoadingIndicator />}
      </PageSection>
    </article>
  );
}

export const SearchResultsPage = withComponentErrorBoundary(SearchResultsPageComp);

type NoResultsProps = {
  query: string;
};

export function SearchResultsLoadingIndicator() {
  const bem = useBemCN('search-results-loading-indicator');

  return (
    <PageSection centered {...bem()}>
      <LoadingLinear type={DeterminationType.Indeterminate} size={'sm'} />
    </PageSection>
  );
}

export function NoResults({ query }: NoResultsProps) {
  const { t } = useTranslation();
  const bem = useBemCN('no-results');

  return (
    <div {...bem()}>
      <SvgIcon {...bem('icon')} Icon={SearchIcon} />
      <p {...bem('title')}>{t(translationKeys.noResultsTitle, { query }).toString()}</p>
      <p {...bem('subtitle')}>{t(translationKeys.noResultsSubtitle).toString()}</p>
      <p {...bem('note')}>
        <HTMLParser rawHtml={t(translationKeys.noResultsNote)} />
      </p>
    </div>
  );
}

interface SearchResultTile<T> {
  hit: Hit<T>;
  row: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onRemove?: () => void;
}

// TODO: move to its own model
export function InkindSearchResult({
  hit,
  onClick = noop,
  onRemove = noop,
  row,
}: SearchResultTile<InkindAlgoliaObject>) {
  const userId = useUserStore(state => state.id);
  const { data: organizingPages } = useUserOrganizingPages(userId);
  const { data: ownedPages } = useUserOwnedPages(userId);
  const isPageOwner = userId && ownedPages?.some(inkind => inkind.routeId == hit.objectID);
  const isPageOrganizer = userId && organizingPages?.some(inkind => inkind.routeId == hit.objectID);

  return (
    <div onClick={onClick}>
      <InkindPageCard
        key={hit.objectID}
        noToolbar
        onRemove={onRemove}
        layout={row ? LayoutTypes.horizontal : LayoutTypes.vertical}
        inkindPage={
          // FIXME: InkindPageCard and child components should not accept the full inkind page model, just what they need.
          // We could use e.g. Required<Pick<InkindPageAPIModel, 'url' | 'title'>>; to select them without having to create
          // completely new models
          // @ts-ignore
          {
            title: hit.pageTitle,
            imgSrc: hit.mediumImageUrl,
            thumb_medium: hit.mediumImageUrl,
            thumb_small: hit.smallImageUrl,
            routeId: hit.objectID,
            slug: hit.slug,
            // @ts-ignore
            owner: {
              email: hit.ownerEmail,
              owner: hit.ownerFullName,
              userId: isPageOwner ? userId : undefined,
            } as Organizer,
            organizers: isPageOrganizer ? [{ userId }] : undefined,
            description: hit.pageDescription,
            recipientFullName: hit.recipientFullName,
            city: hit.recipientCity,
            stateCode: hit.recipientState,
            zip: hit.recipientZipCode,
            location: hit.recipientCity && hit.recipientState ? `${hit.recipientCity}, ${hit.recipientState}` : null,
            address: 'Address',
          } as InkindPageAPIModel
        }
      />
    </div>
  );
}

export function PostSearchResult({ hit, onClick, row }: SearchResultTile<PostAlgoliaObject>) {
  return (
    <div onClick={onClick}>
      <ArticleTile
        layout={row ? 'row' : 'column'}
        article={
          {
            articleSlug: hit.slug,
            smallImageUrl: hit['img-thumbnail'] || hit['img-acf-thumbnail'],
            largeImageUrl: hit['img-api_thumb_square_lg'] || hit['img-acf-thumbnail'],
            title: he.decode(hit.title),
            excerpt: hit.content, // FIXME: need excerpt
            isPreviewOnly: hit.preview_mode,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          } as any // FIXME: cut down what ArticleTile needs
        }
      />
    </div>
  );
}

export function ProductSearchResult({ hit, onClick, row }: SearchResultTile<ProductAlgoliaObject>) {
  const smallImageUrl = hit['gridImage'] || hit['img-offsite_image_small'] || hit['img-thumbnail'];
  const inkindRouteId = extractInkindPageRouteId();

  return (
    <div onClick={onClick}>
      <ProductTile
        toolbar={<ProductTileToolbar itemId={parseInt(hit.objectID)} inkindRouteId={inkindRouteId} />}
        key={hit.slug}
        title={hit.title}
        href={'/products/' + hit.slug}
        imageUrl={smallImageUrl}
        // FIXME: Algolia results aren't consistent here and it actually should return a boolean instead of a string
        containImage={
          hit.tc_istcproduct == 'true' ||
          hit.tc_istcproduct == '1' ||
          hit.pg_ispgproduct == 'true' ||
          hit.pg_ispgproduct == '1'
        }
        direction={row ? 'row' : 'column'}
      />
    </div>
  );
}

export function IntercomArticleSearchResult({ hit, onClick }: SearchResultTile<IntercomArticleAlgoliaObject>) {
  return (
    <div onClick={onClick}>
      <Link href={hit.url}>
        <article>
          <h1>{hit.title}</h1>
          <p>{hit.description}</p>
        </article>
      </Link>
    </div>
  );
}
